<template>
  <div>
    <!-- Second row -->
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Liste des Clients</span>
        </template>

        <customer-list-table />
        <!--TableTacheCours -->
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="UserPlusIcon" />
          <span>Ajouter un Client</span>
        </template>

        <add-customer-form />
        <!--TableTacheCours -->
      </b-tab>
       <b-tab>
        <template #title>
          <feather-icon icon="DownloadIcon" />
          <span>Import de masse</span>
        </template>

        <mass-import />
        <!--TableTacheCours -->
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import { BTabs, BTab } from "bootstrap-vue";
import { BRow, BCol } from "bootstrap-vue";
import TimeSheetTable from "../TimeSheetTable.vue";
import CustomerListTable from "./CustomerListTable.vue";
import AddCustomerForm from "./AddCustomerForm.vue";
import MassImport from "./MassImport.vue";

export default {
  data() {
    return {
      data: {},
      mainProps: {
        blank: true,
        width: 300,
        height: 100,
        class: "m2",
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,

    TimeSheetTable,
    CustomerListTable,
    AddCustomerForm,
    MassImport,
  },

  mounted() {
    
  },
};
</script>

<style>
.add-story-card {
  width: 71%;
  position: absolute;
  bottom: 10px;
  background: white;
  height: 50px;
  margin-bottom: 5px;
}

.add-story-btn {
  margin-top: -24px;
  margin-left: 30px;
  border: 3px solid white;
}

.avatar {
  margin-left: 10px;
}

.employees-card {
  margin-right: 9% !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

