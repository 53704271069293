<template>
  <b-card style="padding: 0rem">
    <b-card-body style="padding: 0rem">
      <div class="">
        <div>
          <div class="mb-2">
            <b-row class="d-flex justify-content-between">
              <b-col cols="auto">
                <h4>Toutes les feuilles de temps</h4>
              </b-col>
              <b-col cols="auto">
                <div class="d-flex">
                  <b-dropdown
                    :disabled="exporting"
                    variant="outline-primary"
                    id="dropdown-1"
                    class="ml-2"
                  >
                    <template #button-content>
                      <b-spinner v-if="exporting" small />
                      <span>Exporter</span>
                    </template>
                    <b-dropdown-item @click="exportToExcel">
                      <feather-icon icon="FileTextIcon" size="15" /> Exporter en
                      Excel</b-dropdown-item
                    >
                    <b-dropdown-item @click="exportToPdf">
                      <feather-icon icon="FileIcon" size="15" /> Exporter en
                      PDF</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
          </div>
          <hr>


          <div class="mb-2">
            <b-row class="d-flex justify-content-between align-items-center">
              <b-col md="3">
                <b-form-group>
                  <b-form-checkbox
                    v-model="filterData.absence"
                  >
                    Uniquement les absences
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label></label>
                  <multiselect
                    v-model="employeeSelected"
                    placeholder="Filtrer par collaborateur"
                    :options="employees"
                    :custom-label="employeeFullname"
                    :value="employeeSelected"
                    selectLabel=""
                    deselectLabel=""
                    :showNoResults="false"
                  >
                    Pas d'élément trouvé
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="">Du :</label>
                  <b-input v-model="filterData.startDate" type="date"></b-input>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group>
                  <label for="">Au :</label>
                  <b-input v-model="filterData.endDate" type="date"></b-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <hr>
          <div class="m-2 d-flex align-items-center">
            <div>
              <h5>Nombre de jours d'absence : <span class="badge badge-danger">{{stats.absences}}</span> </h5>
            </div>
            <div class="ml-2">
              <h5>Nombre de jours de présence : <span class="badge badge-success">{{stats.presences}}</span> </h5>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>


    <b-table
      :busy="isBusy"
      no-border-collapse
      hover
      responsive
      show-empty
      empty-text="Aucun élément"
      class="position-relative"
      :per-page="perPage"
      tbody-tr-class="table-light"
      :current-page="currentPage"
      :items="filteredItems"
      :fields="fields"
      :filter-included-fields="filterOn"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" small></b-spinner>
          <strong> Chargement...</strong>
        </div>
      </template>
      <template #cell(user)="data">
        {{ getUser(data) }}
      </template>
      
      <template #cell(end_pause)="data">
        {{ data.item.arrival ? data.item.end_pause : 'Absent' }}
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Affichage par page"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="allTimesheets.length"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <!-- <div class="row m-2">
      <p>Légende :</p>
      <div class="ml-1">
        <span
          class="fs-6 text-center alert-success"
          style="font-size: 90%; padding: 1vh; border-radius: 10px"
          >Nombre d'heures supérieur à 8</span
        >
      </div>
      <div class="ml-1 xl-top-2">
        <span
          class="fs-6 text-center alert-danger"
          style="font-size: 90%; padding: 1vh; border-radius: 10px"
          >Nombre d'heures inférieur à 8</span
        >
      </div>
      <div class="ml-1">
        <span
          class="fs-6 text-center alert-secondary"
          style="font-size: 90%; padding: 1vh; border-radius: 10px"
          >Journée incomplète</span
        >
      </div>
    </div> -->
  </b-card>
</template>

<script>
import { BProgress } from "bootstrap-vue";
import moment from "moment";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BCard,
    BProgress,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    Multiselect,
  },

  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      stats: {
        absences: 0,
        presences: 0,
      },
      fields: [
        { key: "created_at", label: "Dates", sortable: true },
        { key: "user", label: "Noms et prénoms", sortable: true },

        { key: "arrival", label: "Arrivée" },
        ,
        { key: "start_pause", label: "Début pause" },
        ,
        { key: "end_pause", label: "Fin pause" },
        ,
        { key: "departure", label: "Départ" },
        { key: "total", label: "Total" },
      ],

      filterData: {
        userId: null,
        absence: false,
        startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
      },
      exporting: false,
      employeeSelected: null,
      isBusy: false,
      /* eslint-disable global-require */

      /* eslint-disable global-require */
    };
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapState("enterprise", ["employees", "allTimesheets"]),

    filteredItems() {
      if (this.filterData.userId != null)
        return this.allTimesheets?.filter((item) => {
          return item?.user?.id == this.filterData.userId;
        });

      return this.allTimesheets;
    },
  },

  watch: {
    filterData: {
      deep: true,
      handler() {
        this.isBusy = true;
        this.filterTimesheets(this.filterData).then(({absences, presences}) => {
          this.isBusy = false;
          this.stats.absences = absences;
          this.stats.presences = presences;
        });
      },
    },

    employeeSelected(val) {
      if (val) {
        this.filterData.userId = val.id;
      } else {
        this.filterData.userId = null;
      }
    },
  },

  mounted() {
    // Set the initial number of items
    this.isBusy = true;
    this.filterTimesheets(this.filterData).then(({absences, presences}) => {
      this.isBusy = false;
      this.stats.absences = absences;
      this.stats.presences = presences;
    });
    this.getEmployees();
    this.totalRows = this.allTimesheets.length;

  },
  methods: {
    ...mapActions("enterprise", [
      "getEmployees",
      "fetchEmployees",
      "fetchTimesheets",
      "filterTimesheets",
      "exportTimesheetsToExcel",
      "exportTimesheetsToPdf",
    ]),

    getUser(item) {
      return item.item.user?.firstname + " " + item.item.user?.lastname;
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },

    rowClass(item, type) {
      // const supColor = "table-success text-success";
      // const infColor = "table-danger text-danger";
      // const incColor = "table-light";

      // if (item.arrival == null) {
      //   return incColor;
      // }
      // var total = item.total.split(":")[0];
      // // eslint-disable-next-line consistent-return
      // if (total >= 8) {
      //   return supColor;
      // }
      // if (total < 8) {
      //   return infColor;
      // }
    },

    employeeFullname(item) {
      return item.lastname + " " + item.firstname;
    },

    exportToExcel() {
      this.exporting = true;
      this.exportTimesheetsToExcel(this.filterData)
        .then((response) => {
          this.exporting = false;
          window.location.href = response.data;
        })
        .catch((err) => {
          this.exporting = false;
        });
    },

    exportToPdf() {
      this.exporting = true;
      this.exportTimesheetsToPdf(this.filterData)
        .then((response) => {
          this.exporting = false;
          window.open(response.data, '_blank');
        })
        .catch((err) => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
