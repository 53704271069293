var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Importez vos clients depuis un fichier excel"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"mt-2 text-center"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-card',{staticClass:"d-flex justify-content-center",attrs:{"no-body":""}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/svg/excel.svg"),"width":"300","alt":""}})])],1),_c('div',{staticClass:"mt-2 text-justify pr-2 ",staticStyle:{"border-left":"1px solid rgb(0,0,0,0.2)"}},[_c('p',{staticClass:"ml-2"},[_vm._v("Veuillez choisir le fichier Excel puis renseignez les champs des différentes colonnes pour préciser la position de chaque élément dans votre tableur.")])])])]),_c('b-col',{attrs:{"md":"8"}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('fieldset',{staticStyle:{"border":"1px solid rgb(0, 0, 0, 0.1)"}},[_c('validation-observer',{ref:"importCustomersForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.importExcelCustomers($event)}}},[_c('div',{staticClass:"m-4"},[_c('validation-provider',{attrs:{"name":"File","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fichier :"}},[_c('b-form-file',{attrs:{"placeholder":"Aucun fichier choisi","browse-text":"Choisir","name":"","value":_vm.client.file},model:{value:(_vm.client.file),callback:function ($$v) {_vm.$set(_vm.client, "file", $$v)},expression:"client.file"}}),_c('small',{staticClass:"help-text text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('div',{staticClass:"mt-2 mb-2"},[_c('p',[_vm._v("Veuillez renseigner les colonnes correspondantes :")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Ligne de debut","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ligne de debut :","label-for":"h-address","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"h-address","type":"number","placeholder":"1"},model:{value:(_vm.client.start_row),callback:function ($$v) {_vm.$set(_vm.client, "start_row", $$v)},expression:"client.start_row"}}),_c('small',{staticClass:"help-text text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Colonne 'Nom'","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Colonne 'Nom' :","label-for":"h-first-name","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"h-first-name","placeholder":"B"},model:{value:(_vm.client.name_col),callback:function ($$v) {_vm.$set(_vm.client, "name_col", $$v)},expression:"client.name_col"}}),_c('small',{staticClass:"help-text text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":_vm.isEmailRequired(),"name":"Colonne 'Email'"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Colonne 'Email' :","label-for":"h-email","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"h-email","type":"text","placeholder":"C"},model:{value:(_vm.client.email_col),callback:function ($$v) {_vm.$set(_vm.client, "email_col", $$v)},expression:"client.email_col"}}),_c('small',{staticClass:"help-text text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":_vm.isTelephoneRequired(),"name":"Colonne de téléphone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Colonne 'Téléphone' :","label-for":"h-number","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"h-number","type":"text","placeholder":"D"},model:{value:(_vm.client.telephone_col),callback:function ($$v) {_vm.$set(_vm.client, "telephone_col", $$v)},expression:"client.telephone_col"}}),_c('small',{staticClass:"help-text text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":'required',"name":"Colonne 'Adresse'"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Colonne 'Adresse' :","label-for":"h-address","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"h-address","type":"text","placeholder":"E"},model:{value:(_vm.client.address_col),callback:function ($$v) {_vm.$set(_vm.client, "address_col", $$v)},expression:"client.address_col"}}),_c('small',{staticClass:"help-text text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Colonne 'N° Matricule' :","label-for":"h-address","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"h-address","type":"text","placeholder":"F"},model:{value:(_vm.client.matricule_col),callback:function ($$v) {_vm.$set(_vm.client, "matricule_col", $$v)},expression:"client.matricule_col"}})],1)],1),_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Importer ")]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Annuler ")])],1)],1)],1)])],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }