<template>
  <b-card title="Importez vos clients depuis un fichier excel" class="">
    <b-row>
      <b-col md="4">
        <div class="mt-2 text-center">
          <div class="d-flex justify-content-center">
            <b-card no-body class="d-flex justify-content-center">
              <img
                src="@/assets/images/svg/excel.svg"
                width="300"
                class="img-fluid"
                alt=""
              />
            </b-card>
          </div>
          <div class="mt-2 text-justify pr-2 " style="border-left: 1px solid rgb(0,0,0,0.2)" >
            <p class="ml-2" >Veuillez choisir le fichier Excel puis renseignez les champs
            des différentes colonnes pour préciser la position de chaque élément dans votre
            tableur.</p>
          </div>
        </div>
      </b-col>
      <b-col md="8">
        <div class="d-flex justify-content-center">
          <fieldset style="border: 1px solid rgb(0, 0, 0, 0.1)">
            <validation-observer ref="importCustomersForm" class="">
              <b-form @submit.prevent="importExcelCustomers">
                <div class="m-4">
                  <validation-provider
                    name="File"
                    :rules="'required'"
                    #default="{ errors }"
                  >
                    <b-form-group label="Fichier :">
                      <b-form-file
                        v-model="client.file"
                        placeholder="Aucun fichier choisi"
                        browse-text="Choisir"
                        name=""
                        :value="client.file"
                      />
                      <small class="help-text text-danger">{{
                        errors[0]
                      }}</small>
                    </b-form-group>
                  </validation-provider>
                  <div class="mt-2 mb-2">
                    <p>Veuillez renseigner les colonnes correspondantes :</p>
                  </div>
                  <b-row>
                    <b-col cols="12">
                      <validation-provider
                        name="Ligne de debut"
                        :rules="'required'"
                        #default="{ errors }"
                      >
                        <b-form-group
                          label="Ligne de debut :"
                          label-for="h-address"
                          label-cols-md="4"
                        >
                          <b-form-input
                            id="h-address"
                            v-model="client.start_row"
                            type="number"
                            placeholder="1"
                          />
                          <small class="help-text text-danger">{{
                            errors[0]
                          }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        name="Colonne 'Nom'"
                        :rules="'required'"
                        #default="{ errors }"
                      >
                        <b-form-group
                          label="Colonne 'Nom' :"
                          label-for="h-first-name"
                          label-cols-md="4"
                        >
                          <b-form-input
                            id="h-first-name"
                            placeholder="B"
                            v-model="client.name_col"
                          />
                          <small class="help-text text-danger">{{
                            errors[0]
                          }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        :rules="isEmailRequired()"
                        #default="{ errors }"
                        name="Colonne 'Email'"
                      >
                        <b-form-group
                          label="Colonne 'Email' :"
                          label-for="h-email"
                          label-cols-md="4"
                        >
                          <b-form-input
                            id="h-email"
                            type="text"
                            placeholder="C"
                            v-model="client.email_col"
                          />
                          <small class="help-text text-danger">{{
                            errors[0]
                          }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        :rules="isTelephoneRequired()"
                        name="Colonne de téléphone"
                        #default="{ errors }"
                      >
                        <b-form-group
                          label="Colonne 'Téléphone' :"
                          label-for="h-number"
                          label-cols-md="4"
                        >
                          <b-form-input
                            v-model="client.telephone_col"
                            id="h-number"
                            type="text"
                            placeholder="D"
                          />
                          <small class="help-text text-danger">{{
                            errors[0]
                          }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        #default="{ errors }"
                        :rules="'required'"
                        name="Colonne 'Adresse'"
                      >
                        <b-form-group
                          label="Colonne 'Adresse' :"
                          label-for="h-address"
                          label-cols-md="4"
                        >
                          <b-form-input
                            id="h-address"
                            v-model="client.address_col"
                            type="text"
                            placeholder="E"
                          />
                          <small class="help-text text-danger">{{
                            errors[0]
                          }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Colonne 'N° Matricule' :"
                        label-for="h-address"
                        label-cols-md="4"
                      >
                        <b-form-input
                          id="h-address"
                          v-model="client.matricule_col"
                          type="text"
                          placeholder="F"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- submit and reset -->
                    <b-col offset-md="4">
                      <b-button type="submit" variant="primary" class="mr-1">
                        Importer
                      </b-button>
                      <b-button type="reset" variant="outline-secondary">
                        Annuler
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-form>
            </validation-observer>
          </fieldset>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import KyInput from "@/@core/layouts/components/ky-input.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, email, confirmed } from "@validations";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    KyInput,
    ToastificationContent,
  },
  data() {
    return {
      addOtherCustomer: true,
      joinCustomerForm: false,
      clientDefault: {
        name_col: "",
        email_col: "",
        telephone_col: "",
        address_col: "",
        file: "",
        start_row: "",
        matricule_col: "",
      },
      client: {
        name_col: "",
        email_col: "",
        telephone_col: "",
        address_col: "",
        file: "",
        start_row: 1,
        matricule_col: "",
      },
      loading: false,
      errorMessage: "",
      error: "",
      errors: "",
      errorModal: false,
      required,
      confirmed,
      email,
      showCustomerForm: false,
      showErrors: false,
    };
  },
  computed: {
    ...mapState("enterprise", ["clients"]),
  },
  mounted() {},
  watch: {},
  methods: {
    isEmailRequired() {
      if (this.client.telephone_col == "") {
        return "required";
      }
    },
    isTelephoneRequired() {
      if (this.client.email_col == "") {
        return "required";
      }
    },

    async importExcelCustomers() {
      const valid = await this.$refs.importCustomersForm.validate();
      if (!valid) return;
      this.loading = true;
      this.importCustomers(this.$formData(this.client))
        .then((response) => {
          this.loading = true;
          //this.clearForm();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Clients ajoutés avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          // this.errorMessage = error.message;
          // this.errors = error.response.data;
        });
    },

    closeThisForm() {
      this.client.name_col = "";
      this.client.telephone_col = "";
      this.client.address_col = "";
      this.client.email_col = "";
    },

    clearForm() {
      this.client = this.clientDefault;
      this.$nextTick().then((response) => {
        this.$refs.importCustomersForm.reset();
      });
    },

    ...mapActions("enterprise", ["importCustomers"]),
  },
};
</script>
