<template>
  <b-card>
    <b-card>
      <div class="row">
        <h4>
          Mes Clients <span>( {{ clients.length }} )</span>
        </h4>
        <b-form-group
          label-cols-sm="0"
          label-align-sm="left"
          label-size=""
          label-for="filterInput"
          class="ml-auto"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Rechercher..."
            />
            <b-input-group-append>
              <b-button @click="filter = ''" class="btn btn-secondary">
                Effacer
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card>
    <b-row>
      <b-col :md="colTable">
        <!-- Table beginning -->
        <b-table
          :busy="isBusy"
          sticky-header="520px"
          hover
          no-border-collapse
          ref="refInvoiceListTable"
          responsive
          :per-page="parPage"
          :current-page="currentPage"
          :items="clients"
          :fields="clientsItems"
          show-empty
          empty-text="Pas de client disponible"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          @row-clicked="customerDetails"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Chargement...</strong>
            </div>
          </template>
          <template #cell(logo)="data">
            <b-avatar :src="data.item.logo"></b-avatar>
          </template>
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <span class="font-weight-bold d-block text-nowrap">{{
                data.item.name
              }}</span>
              <span v-if="data.item.responsible" class="text-muted">
                {{
                  data.item.responsible.firstname +
                  " " +
                  data.item.responsible.lastname
                }}
              </span>
              <span v-else class="text-muted"> Aucun responsable </span>
            </b-media>
          </template>
          <!-- Column: Id -->

          <template #cell(action)="data">
            <button
              class="mr-1 btn btn-icon btn-outline-danger rounded-circle"
              @click="deleteThisCustomer(data.item)"
            >
              <feather-icon icon="TrashIcon" size="18" />
            </button>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Affichage par page"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="parPage"
              size="sm"
              inline
              :options="optionPage"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="parPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-col>
      <b-col v-if="client != {}" v-show="colDetail" md="">
        <b-overlay :show="loadCustomerDetails">
          <b-card border-variant="">
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-row>
                  <b-col cols="4">
                    <b-media-body>
                      <!-- +++++++++++ -->
                      <b-avatar size="4em" :src="client.logo" :icon="'image'">
                        <template #badge>
                          <input
                            accept="image/*"
                            type="file"
                            hidden
                            id="logo"
                            @change="onFileUpload"
                          />
                          <b-icon
                            @click="openFilePicker"
                            style="cursor: pointer"
                            icon="camera"
                          ></b-icon>
                        </template>
                      </b-avatar>
                      <!-- +++++++++++ -->
                    </b-media-body>
                  </b-col>
                  <b-col cols="8">
                    <h3>{{ client.name }}</h3>
                    <p>{{ client.address }}</p>
                  </b-col>
                </b-row></b-col
              >
              <b-col cols="auto">
                <b-link
                  @click="fetchInvoices(client)"
                  no-prefetch
                  v-b-toggle.invoices-sidebar
                  >Voir les factures ></b-link
                >
              </b-col>
            </b-row>
            <validation-observer ref="editCustomerForm">
              <b-form>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <b-form-input v-model="client.name" />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <b-form-input
                        v-model="client.address"
                        placeholder="Adresse"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <b-form-input
                        v-model="client.telephone"
                        placeholder="Téléphone"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <b-form-input
                        v-model="client.email"
                        placeholder="Email"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>

            <div class="text-right m-1">
              <b-button
                class="btn-sm mr-1"
                variant="outline-primary"
                @click.prevent="resetDetailCol"
                >Annuler</b-button
              >
              <b-button class="btn-sm" variant="primary" @click="editCustomer"
                >Mettre à jour</b-button
              >
            </div>
            <hr />
            <div class="d-flex justify-content-between">
              <h5>
                Responsable :
                <b-badge variant="warning" v-if="client.responsible != null"
                  >{{ client.responsible.firstname }}
                  {{ client.responsible.lastname }}
                </b-badge>
                <small class="text-warning" v-else
                  >Aucun responsable désigné</small
                >
              </h5>
              <div v-if="wantChangeResponsible == true" class="d-flex">
                <multiselect
                  v-model="responsibleMultiSelect"
                  placeholder="Responsable..."
                  :options="masters"
                  :custom-label="userName"
                  :value="responsibleMultiSelect"
                  selectLabel=""
                  deselectLabel=""
                  :showNoResults="false"
                >
                  Pas d'element trouvé
                </multiselect>
                <b-button
                  class="p-1 ml-1"
                  variant="outline-danger"
                  @click="wantChangeResponsible = false"
                  >X</b-button
                >
              </div>
              <b-button
                v-if="wantChangeResponsible == false"
                @click="wantChangeCustomerResponsible()"
                v-b-toggle.allTasksNoBilled-sidebar
                size="sm"
                variant="outline-primary"
              >
                Changer
              </b-button>
            </div>

            <hr />
            <div class="text-right">
              <b-button
                @click="fetchTasks(client)"
                v-b-toggle.allTasksNoBilled-sidebar
                size="sm"
                variant="outline-primary"
              >
                Liste des tâches non-facturées
              </b-button>
            </div>
          </b-card>

          <!-- INVOICES SIDEBAR -->
          <b-sidebar
            no-close-on-backdrop
            no-header-close
            :title="'Liste des factures de : ' + client.name"
            id="invoices-sidebar"
            ref="mySideBar"
            bg-variant="white"
            width="1000px"
            right
            shadow
          >
            <template #footer="{ hide }">
              <div
                class="d-flex bg-dark text-light align-items-center px-1 py-1"
              >
                <strong class="mr-auto"></strong>
                <b-button size="sm" @click="hide">Fermer</b-button>
              </div>
            </template>
            <b-card>
              <div class="px-1 py-1">
                <div>
                  <b-table
                    empty-text="Aucune factures pour ce client"
                    show-empty
                    responsive
                    head-variant="dark"
                    striped
                    :items="allCustomerInvoices"
                    :fields="CustomerInvoicesfields"
                  >
                    <template #cell(#)>
                      <img src="../../../../public/pdf.svg" width="25" alt="" />
                    </template>
                    <template #cell(customer)="data">
                      {{ data.item.customer.name }}
                    </template>
                    <template #cell(date)="data">
                      {{ getDate(data.item.created_at) }}
                    </template>

                    <template #cell(is_paid)="data">
                      <b-form-checkbox
                        :disabled="
                          userInfo.id != data.item.is_check_paid_by &&
                          data.item.is_check_paid_by != null
                        "
                        v-b-tooltip.hover
                        :title="
                          data.item.is_paid
                            ? 'Marquer comme impayée'
                            : 'Marquer comme payée '
                        "
                        :checked="true"
                        v-model="data.item.is_paid"
                        class="custom-control-primary"
                        @change="_markInvoiceIsPaid(data.item)"
                      />
                    </template>

                    <template #cell(actions)="data">
                      <b-dropdown
                        variant="link"
                        no-caret
                        class="chart-dropdown"
                        toggle-class="p-0"
                        right
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="18"
                            class="text-body cursor-pointer"
                          />
                        </template>

                        <b-dropdown-item
                          v-if="data.item.is_validate == true"
                          :href="data.item.filename_invoice"
                          target="_blank"
                          variant="outline-primary"
                          size="sm"
                        >
                          <feather-icon
                            icon="DownloadIcon"
                            size="18"
                            style="margin-right: 5px"
                          />
                          Télécharger la facture
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-else
                          :href="data.item.filename_devis"
                          target="_blank"
                          variant="outline-primary"
                          size="sm"
                          ><b-icon icon="MoreVerticalIcon"> </b-icon>

                          <feather-icon
                            icon="DownloadIcon"
                            size="18"
                            style="margin-right: 5px"
                          />
                          Télécharger le devis
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-table>
                </div>
              </div>
            </b-card>
          </b-sidebar>

          <!-- ALL TASKS NO BILLED SIDEBAR -->
          <b-sidebar
            no-close-on-backdrop
            no-header-close
            :title="'Liste des tâches non facturées de : ' + client.name"
            id="allTasksNoBilled-sidebar"
            ref="mySideBar"
            bg-variant="white"
            width="85%"
            right
            shadow
          >
            <template #footer="{ hide }">
              <div
                class="d-flex bg-dark text-light align-items-center px-1 py-1"
              >
                <strong class="mr-auto"></strong>
                <b-button size="sm" @click="hide">Fermer</b-button>
              </div>
            </template>
            <div class="px-1 py-1">
              <div>
                <b-table
                  empty-text="Aucune tâche pour ce client"
                  show-empty
                  responsive
                  head-variant="dark"
                  striped
                  :items="tasksNotBilled"
                  :fields="TasksNotBilledfields"
                >
                  <template #cell(#)="data">
                    <b-form-checkbox
                      v-model="data.item.billable"
                      @change="_markBillable(data.item)"
                      class="custom-control-primary"
                      ><p
                        v-if="data.item.billable == true"
                        class="alert alert-success"
                      >
                        Facturable
                      </p>
                      <p
                        v-if="data.item.billable == false"
                        class="alert alert-warning"
                      >
                        Non facturable
                      </p>
                    </b-form-checkbox>
                  </template>
                  <template #cell(start_date)="data">
                    {{ getDate(data.item.start_date) }}
                  </template>
                  <template #cell(end_date)="data">
                    {{ getDate(data.item.end_date) }}
                  </template>
                  <template #cell(master)="data">
                    {{ data.item.master.firstname }}
                    {{ data.item.master.lastname }}
                  </template>
                  <template #cell(supervisor)="data">
                    {{ data.item.supervisor.firstname }}
                    {{ data.item.supervisor.lastname }}
                  </template>
                </b-table>
              </div>
            </div></b-sidebar
          >
        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: {
    ToastificationContent,
    Multiselect,
  },
  data() {
    return {
      wantChangeResponsible: false,
      responsibleMultiSelect: null,
      isBusy: false,
      responsible: null,
      loadCustomerDetails: false,
      errors: "",
      client: {},
      colTable: 12,
      colDetail: false,
      customer_id: null,
      rows: 0,
      parPage: 10,
      optionPage: [5, 10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      changedResponsibleData: {
        customer_id: null,
        responsible_id: null,
      },
      filterOn: [],

      CustomerInvoicesfields: [
        {
          key: "num",
          label: "Numéro",
          sortable: true,
        },
        {
          key: "totalTTC",
          label: "Total TTC(€)",
          sortable: true,
        },
        {
          key: "is_paid",
          label: "Soldée",
          sortable: true,
        },
        {
          key: "date",
          sortable: true,
        },
        {
          key: "actions",
          thStyle: { width: "30px" },
        },
      ],

      TasksNotBilledfields: [
        {
          key: "#",
          label: "#",
          thStyle: { width: "15%" },
        },
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Date Debut",
          sortable: true,
        },
        {
          key: "end_date",
          label: "Date Fin",
          sortable: true,
        },
        {
          key: "master",
          label: "Responsable",
          sortable: true,
        },
        {
          key: "supervisor",
          label: "Superviseur",
          sortable: true,
        },
        // {
        //   key: "actions",
        //   thStyle: { width: "30px" },
        // },
      ],

      clientsItems: [
        { key: "logo", label: "" },
        {
          key: "name",
          label: "Nom",
          sortable: true,
          thStyle: { width: "300px" },
        },
        { key: "telephone", label: "Téléphone", sortable: true },
        { key: "address", label: "Adresse", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "action", label: "", sortable: true },
      ],
      /* eslint-disable global-require */
    };
  },
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapState("enterprise", ["clients", "employees"]),
    ...mapState("tasks", ["allCustomerInvoices", "tasksNotBilled"]),

    masters() {
      return this.employees;
    },

    sortOptions() {
      // Create an options list from our fields
      return this.clientsItems
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.getEmployees();
    this.isBusy = true;
    this.fetchCustomers().then(() => {
      this.isBusy = false;
    });
    this.totalRows = this.clients.length;
    // this.fetchCustomers();
  },

  watch: {
    responsibleMultiSelect(val) {
      this.changedResponsibleData.customer_id = this.client.id;
      this.changedResponsibleData.responsible_id = val.id;
      this.changeResponsible(this.$formData(this.changedResponsibleData))
        .then((res) => {
          this.wantChangeResponsible = false;
          this.colDetail = false;
          this.colTable = 12;
          this.fetchCustomers().then(() => {
            this.isBusy = false;
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Responsable modifié avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur s'est produite",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    // client(val) {
    //   if (val.responsible == null) {
    //     this.customerResponsible(val.id)
    //       .then((res) => {
    //         this.responsible = res.data;
    //         this.responsibleMultiSelect = this.responsible;
    //         this.userName(
    //           this.responsible.firstname,
    //           this.responsible.lastname
    //         );
    //       })
    //       .catch(() => {
    //         this.responsible = null;
    //       });
    //   }
    // },
  },

  methods: {
    wantChangeCustomerResponsible() {
      this.wantChangeResponsible = true;
    },

    userName({ firstname, lastname }) {
      return firstname + " " + lastname;
    },

    _markInvoiceIsPaid(data) {
      this.checkInvoiceIsPaid(data.id)
        .then((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Opération éffectuée avec succès",
              icon: "EditIcon",
              variant: "success",
            },
            queue: false,
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur",
              icon: "EditIcon",
              variant: "danger",
            },
            queue: false,
          });
        });
    },

    _markBillable(task) {
      this.taskBillable({ task: task }).then((r) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Opération éffectuée.",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },

    fetchInvoices(data) {
      this.customerInvoices(data.id);
    },

    fetchTasks(data) {
      this.allTasksNotBilled(data.id);
    },

    getDate(data) {
      return moment(data).locale("fr").format("DD/MM/YYYY");
    },

    deleteThisCustomer(data) {
      this.deleteCustomer(data.id).then(() => {
        this.isBusy = true;
        this.fetchCustomers().then(() => {
          this.isBusy = false;
        }).then((err) => {
          this.isBusy = false;
        });
      }).catch(err => {
        this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error lors du retrait du client",
              variant: "error",
            },
          });
      });
    },

    customerDetails(data) {
      this.loadCustomerDetails = true;
      this.colTable = 6;
      this.colDetail = true;
      this.showCustomer(data.id).then((e) => {
        this.client = data;
        this.loadCustomerDetails = false;
      });
      // this.showCustomer(data)
    },

    onFileUpload(event) {
      this.uploaded = true;
      var file = event.target.files[0];
      if (file) this.imageUrl = URL.createObjectURL(file);

      this.client.logo = file;
      this.client.logo_only = 1;
      this.editCustomer();
    },
    openFilePicker() {
      document.getElementById("logo").click();
    },

    resetDetailCol() {
      this.colDetail = false;
      this.colTable = 12;
    },

    async editCustomer() {
      const valid = await this.$refs.editCustomerForm.validate();
      if (!valid) return;
      this.loading = true;
      this.updateCustomer(this.$formData(this.client))
        .then((response) => {
          this.colDetail = false;
          this.colTable = 12;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Client modifié avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.errors = error.response;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Données existantes",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    onFiltered(clients) {
      this.totalRows = clients.length;
      this.currentPage = 1;
    },
    ...mapActions("tasks", [
      "tasks",
      "customerInvoices",
      "allTasksNotBilled",
      "taskBillable",
      "checkInvoiceIsPaid",
    ]),
    ...mapActions("enterprise", [
      "fetchCustomers",
      "showCustomer",
      "updateCustomer",
      "deleteCustomer",
      "getEmployees",
    ]),

    ...mapActions("customer", ["customerResponsible", "changeResponsible"]),
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
