<template>
  <b-card title="Chercher le client par email ou par numéro de téléphone">
    <validation-observer
      v-show="addOtherCustomer"
      ref="controlCustomerForm"
      class=""
    >
      <b-form>
        <b-row>
          <!-- email input -->
          <b-col md="6">
            <b-form-group>
              <ky-input
                :placeholder="'Email du client ou Télephone'"
                :type="'email'"
                v-model="client.email"
                :name="'email'"
                :rules="isEmailRequired"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <ky-input
                :placeholder="'Téléphone du client'"
                :type="'number'"
                v-model="client.telephone"
                :name="'telephone'"
                :rules="isTelephoneRequired"
              />
            </b-form-group>
          </b-col>
          <!-- check -->

          <!-- submit button -->
          <b-col md="6" class="pt-2">
            <b-button
              variant="primary"
              type="submit"
              :disabled="loading"
              @click.prevent="controlExistCustomer"
            >
            <b-spinner v-if="loading" small ></b-spinner>
              Rechercher
            </b-button>
          </b-col>
        </b-row>
        <!-- <div class="alert alert-danger p-1" v-show="showErrors">
          <span> Aucun client ne correspond à ce email, veuillez l'ajouter à vos clients </span>
        </div> -->
      </b-form>
    </validation-observer>
    <hr />
    <b-card
      title="Ce client existe déjà parmis vos clients"
      v-show="joinCustomerForm"
    >
      <validation-observer ref="joinCustomerForm">
        <b-form>
          <b-row>
            <!-- email input -->
            <b-col md="6">
              <b-form-group>
                <ky-input
                  :placeholder="'Email du client'"
                  :type="'email'"
                  :rules="'required'"
                  v-model="client.email"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- firstname input -->
            <b-col md="6">
              <b-form-group>
                <ky-input
                  :placeholder="'Nom du client'"
                  :type="'text'"
                  :rules="'required'"
                  v-model="client.name"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- New row -->
          <b-row>
            <!-- phone input -->
            <b-col md="6">
              <b-form-group>
                <ky-input
                  :placeholder="'Téléphone du client'"
                  :type="'number'"
                  :rules="'required'"
                  v-model="client.telephone"
                  disabled
                />
              </b-form-group>
            </b-col>

            <!-- adress input -->
            <b-col md="6">
              <b-form-group>
                <ky-input
                  :placeholder="'Adresse du client'"
                  :type="'text'"
                  :rules="'required'"
                  v-model="client.address"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <!-- check -->

          <!-- submit button -->
          <b-row class="d-flex flex-row-reverse">
            <b-col md="auto" class="">
              <b-button
                v-b-modal.modal-1
                variant="primary"
                :disabled="isCreating"
                type="submit"
                @click.prevent="joinThisCustomer"
              >
              <b-spinner v-if="isCreating" small></b-spinner>
                Ajouter à mes clients
              </b-button>
            </b-col>
            <b-col md="auto" class="">
              <b-button @click.prevent="closeThisForm" variant="light">
                Annuler
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card v-show="showCustomerForm" title="Ajouter ce client à vos clients">
      <validation-observer ref="addCustomerForm">
        <b-form>
          <b-row>
            <!-- email input -->
            <b-col md="6">
              <b-form-group>
                <ky-input
                  :placeholder="'Email du client'"
                  :type="'email'"
                  :rules="isEmailRequired"
                  v-model="client.email"
                />
              </b-form-group>
            </b-col>
            <!-- firstname input -->
            <b-col md="6">
              <b-form-group>
                <ky-input
                  :placeholder="'Nom du client'"
                  :type="'text'"
                  :rules="'required'"
                  v-model="client.name"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- New row -->
          <b-row>
            <!-- phone input -->
            <b-col md="6">
              <b-form-group>
                <ky-input
                  :placeholder="'Téléphone du client'"
                  :type="'number'"
                  :rules="isTelephoneRequired"
                  v-model="client.telephone"
                />
              </b-form-group>
            </b-col>

            <!-- adress input -->
            <b-col md="6">
              <b-form-group>
                <ky-input
                  :placeholder="'Adresse du client'"
                  :type="'text'"
                  v-model="client.address"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <!-- check -->

          <!-- submit button -->
          <b-row class="d-flex flex-row-reverse">
            <b-col md="auto" class="">
              <b-button
                v-b-modal.modal-1
                variant="primary"
                :disabled="isCreating"
                type="submit"
                @click.prevent="addThisCustomer"
              >
              <b-spinner v-if="isCreating" small ></b-spinner>
                Ajouter à mes clients
              </b-button>
            </b-col>
            <b-col md="auto" class="">
              <b-button
                v-b-modal.modal-1
                variant="light"
                type="submit"
                @click.prevent="closeThisForm"
              >
                Annuler
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-card>
</template>

<script>
import KyInput from "@/@core/layouts/components/ky-input.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCard,
  BButton,
  BToast,
} from "bootstrap-vue";
import { required, email, confirmed } from "@validations";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BToast,
    KyInput,
    ToastificationContent,
  },
  data() {
    return {
      client: {},
      addOtherCustomer: true,
      joinCustomerForm: false,
      clientDefault: {
        name: "",
        email: "",
        telephone: "",
        address: "",
      },
      loading: false,
      errorMessage: "",
      error: "",
      errors: "",
      errorModal: false,
      required,
      confirmed,
      email,
      isCreating : false,
      showCustomerForm: false,
      showErrors: false,
    };
  },
  computed: {
    isEmailRequired() {
      if (this.client.telephone == null) {
        return "required";
      }
    },
    isTelephoneRequired() {
      if (this.client.email == null) {
        return "required";
      }
    },
    ...mapState("enterprise", ["employees", "clients"]),
  },
  mounted() {},
  watch: {},
  methods: {
    closeThisForm() {
      this.client.name = "";
      this.client.telephone = "";
      this.client.address = "";
      this.showErrors = false;
      this.joinCustomerForm = false;
      this.showCustomerForm = false;
    },
     controlExistCustomer() {
      const valid = this.$refs.controlCustomerForm.validate();
      if (!valid) return;
      this.loading = true;
      this.existCustomer(this.$formData(this.client))
        .then((r) => {
          this.loading = false;
          this.client = r.data.data;
          this.joinCustomerForm = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ce client existe déjà",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.showErrors = true;
          this.showCustomerForm = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ce client n'existe pas, veuillez l'ajouter",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.errorMessage = error.message;
          this.errors = error.response.data;
        });
    },

    async addThisCustomer() {
      const valid = await this.$refs.addCustomerForm.validate();
      if (!valid) return;
      this.isCreating = true;
      this.addCustomer(this.$formData(this.client))
        .then((r) => {
          this.isCreating = false;
          this.showErrors = false;
          this.showCustomerForm = false;
          this.clearForm();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Client ajouté avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.isCreating = false
          this.errorMessage = error.message;
          this.errors = error.response.data;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur s'est produite",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    async joinThisCustomer() {
      this.joinCustomer(this.client.id)
        .then((e) => {
          this.joinCustomerForm = false;
          this.isCreating = true;
          this.clearForm();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Client ajouté avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.isCreating = false
          this.errorMessage = error.message;
          this.errors = error.response.data;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur s'est produite",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    clearForm() {
      this.client = this.clientDefault;
      this.$nextTick().then((response) => {
        this.$refs.addCustomerForm.reset();
      });
    },

    ...mapActions("enterprise", [
      "addCustomer",
      "existCustomer",
      "joinCustomer",
    ]),
  },
};
</script>
